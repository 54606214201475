import React, { useEffect, useState } from 'react';
import { FormViewer } from '@wix/form-viewer/widget';
import { FormViewerWithAIProps } from './interfaces';
import { FormAssistantChat } from '../FormAssistantChat';
import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import AiIcon from '../../../../../assets/images/Glyph.svg';
import classNames from 'classnames';
import { classes } from './FormViewerWithAi.st.css';

import styles from './FormViewerWithAi.scss';

export const FORM_VIEWER_WITH_AI_HOOKS = {
  container: 'FormViewerWithAIContainer',
  openAssistantButtonWrapper: 'FormViewerWithAIOpenAssistantButtonWrapper',
  assistantButton: 'AssistantButton',
  assistantChat: 'AssistantChat',
};

export const FormViewerWithAI = (props: FormViewerWithAIProps) => {
  const { aiAssistantProps, formViewerProps } = props;
  const { aiAssistantService, aiAssistantState } = aiAssistantProps;
  const { onChange, values } = formViewerProps;

  const [aiAssistantOpen, setAiAssistantOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      aiAssistantState?.formData &&
      values &&
      JSON.stringify(aiAssistantState?.formData) !== JSON.stringify(values)
    ) {
      onChange(aiAssistantState?.formData);
    }
  }, [aiAssistantState?.formData]);

  const openAiAssistant = () => {
    setAiAssistantOpen(true);
    aiAssistantService.initThread(values);
  };

  const closeChat = () => {
    setAiAssistantOpen(false);
  };

  return (
    <div
      className={classes.container}
      data-hook={FORM_VIEWER_WITH_AI_HOOKS.container}
    >
      {aiAssistantOpen && (
        <FormAssistantChat
          dataHook={FORM_VIEWER_WITH_AI_HOOKS.assistantChat}
          aiAssistantService={aiAssistantService}
          aiAssistantState={aiAssistantState}
          formValues={values}
          onClose={closeChat}
        />
      )}
      <div>
        <div
          className={classNames(
            styles.buttonContainer,
            classes.buttonContainer,
          )}
          data-hook={FORM_VIEWER_WITH_AI_HOOKS.openAssistantButtonWrapper}
        >
          <Button
            upgrade
            priority={ButtonPriority.primary}
            onClick={openAiAssistant}
            data-hook={FORM_VIEWER_WITH_AI_HOOKS.assistantButton}
          >
            <div className={styles.buttonContent}>
              <AiIcon />
              {t('ai_assistant_button')}
            </div>
          </Button>
        </div>
        <FormViewer {...formViewerProps} />
      </div>
    </div>
  );
};
