import React, { FC } from 'react';
import { ChatHeaderProps } from './interfaces';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { CloseSmall } from '@wix/wix-ui-icons-common/on-stage';

import s from './ChatHeader.scss';

export const ASSISTANT_HEADER_HOOKS = {
  close: 'close-ai-assistant',
};

export const ChatHeader: FC<ChatHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.headerLine}>
          {t('ai_assistant_header').toUpperCase()}
        </div>
        <div className={s.descriptionLine}>
          {t('ai_assistant_header_description')}
        </div>
      </div>
      <div>
        <CloseSmall
          onClick={onClose}
          className={s.closeIcon}
          data-hook={ASSISTANT_HEADER_HOOKS.close}
        />
      </div>
    </div>
  );
};
