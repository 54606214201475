import React, { FC } from 'react';
import { FormAssistantChatProps } from './interfaces';
import { ChatBubble } from './ChatBubble';
import { ChatInput } from './ChatInput';
import { Role } from '@wix/ambassador-forms-assistant-v1-message/types';
import { ChatHeader } from './ChatHeader';

import { classes } from './FromAssistantChat.st.css';

export const FormAssistantChat: FC<FormAssistantChatProps> = ({
  formValues,
  aiAssistantService,
  aiAssistantState,
  onClose,
  dataHook,
}) => {
  const submitUserMessage = (message: string) => {
    aiAssistantService.submitUserMessage({
      message,
      formValues,
    });
  };

  return (
    <div className={classes.container} data-hook={dataHook}>
      <ChatHeader onClose={onClose} />
      <div className={classes.chatContainer}>
        {aiAssistantState.apiErrorOcurred && (
          <div className={classes.error}>Error in API</div>
        )}
        {aiAssistantState?.assistantTyping && (
          <ChatBubble creatorRole={Role.ASSISTANT} loading />
        )}
        {aiAssistantState?.messages?.map((message) => (
          <ChatBubble
            creatorRole={message.role}
            message={message.content}
            key={message.id}
          />
        ))}
      </div>
      <div className={classes.inputContainer}>
        <ChatInput
          onSubmit={submitUserMessage}
          disabled={aiAssistantState.assistantTyping}
        />
      </div>
    </div>
  );
};
