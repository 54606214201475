import React, { FC } from 'react';
import { ChatBubbleProps } from './interfaces';
import AiIcon from '../../../../../../assets/images/Glyph.svg';
import classNames from 'classnames';
import { Avatar, AvatarSize, Text } from 'wix-ui-tpa/cssVars';
import { ThreeDotsLoader } from 'wix-ui-tpa';
import { Role } from '@wix/ambassador-forms-assistant-v1-message/types';

import styles from './ChatBubble.scss';

export const ASSISTANT_CHAT_MESSAGE_HOOKS = {
  root: 'aiAssistant-chat-message',
  content: 'aiAssistant-chat-message-content',
  typing: 'aiAssistant-chat-typing',
};

export const ChatBubble: FC<ChatBubbleProps> = ({
  loading,
  message,
  creatorRole: creator,
}) => {
  return (
    <div
      data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.root}
      className={classNames({
        [styles.container]: true,
        [styles.systemMessage]: creator === Role.ASSISTANT,
        [styles.userMessage]: creator === Role.USER,
      })}
    >
      <div className={styles.creatorContainer}>
        {creator === Role.ASSISTANT ? (
          <AiIcon />
        ) : (
          <Avatar
            size={AvatarSize.small}
            src="https://static.wixstatic.com/media/29a344_01afcd3bcf8c4397bebec7ae8b749d7b~mv2.jpg/v1/fill/w_420,h_420,al_c,lg_1,q_80,enc_auto/Avatar%201.jpg"
          />
        )}
      </div>
      <div className={styles.messageContainer}>
        {loading ? (
          <div
            className={styles.loaderContainer}
            data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.typing}
          >
            <ThreeDotsLoader className={styles.loader} />
          </div>
        ) : (
          <Text
            data-hook={ASSISTANT_CHAT_MESSAGE_HOOKS.content}
            className={styles.text}
          >
            {message}
          </Text>
        )}
      </div>
    </div>
  );
};
